import React from 'react'
import {connect} from 'react-redux'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {Location} from 'history'

import ReactSwipe from 'react-swipe'

import Modal from 'react-bootstrap/Modal'

import * as types from '../store/types'
import {
  itemsById,
  selectedVisibleItemIndex,
  getSortedVisibleItems,
} from '../selectors'
import {
  getMyFaveBundle,
  getSelectedItemBundles,
  getSelectedBundle,
} from '../selectors/bundles'
import {thunks, actions} from '../actions'
import {StoreDispatch} from '../store'

import styles from './ItemDetailModal.module.scss'
import utilityStyles from './common.module.scss'

import {FloatingItemNavigationButton} from './FloatingItemNavigationButton'
import {
  ItemDetailModalGalleryItem,
  ItemDetailCardGalleryItem,
} from './ItemDetailGalleryItem'
import {ART_CARD_FILES_CENTS} from '../sharedConstants'
import {AnimatePresence, motion} from 'framer-motion'

interface Props extends RouteComponentProps<{id: string}> {
  item: types.GriffonItem
  user: types.UserState | null
  isFave: boolean
  hasFaveBundle: boolean
  bundles: types.ItemBundle[]
  selectedItemId: string | null
  selectedBundleId: string | undefined
  nextItem: types.GriffonItem | null
  previousItem: types.GriffonItem | null
  hasPatreonArtAccess: boolean
  hasPatreonCardAccess: boolean
  detailMode: types.DetailMode
  exit: () => void
  setSelectedItemId: (id: string) => void
  setItemFave: (item: types.GriffonItem, fave: boolean) => void
  addItemToBundle: (itemId: string, bundleId: string) => void
}

const listIdFromLocation = (location: Location) =>
  location.pathname.match(/\/stashes\//) ? 'stash' : 'home'

const mapStateToProps = (
  state: types.RootState,
  ownProps: RouteComponentProps<{id: string}>,
) => {
  const patreonPledgeCents = state.auth.user?.patreonEntitledAmountCents
  const listId = listIdFromLocation(ownProps.location)
  const currentIndex = selectedVisibleItemIndex(state, listId)
  const itemId = ownProps.match.params.id
  const faveBundle = getMyFaveBundle(state)
  const isFave = faveBundle ? faveBundle.items.includes(itemId) : false
  return {
    user: state.auth.user,
    item: itemsById(state)[itemId],
    selectedItemId: state.selectedItemId,
    bundles: getSelectedItemBundles(state),
    selectedBundleId: getSelectedBundle(state)?.id,
    hasFaveBundle: !!faveBundle,
    isFave,
    detailMode: state.ui.detailMode,
    exit: () =>
      ownProps.history.push(
        ownProps.history.location.pathname.replace(/\/items\/.*$/, ''),
      ),
    nextItem:
      currentIndex !== null
        ? getSortedVisibleItems(state, listId)[currentIndex + 1]
        : null,
    previousItem:
      currentIndex !== null
        ? getSortedVisibleItems(state, listId)[currentIndex - 1]
        : null,
    hasPatreonArtAccess:
      patreonPledgeCents !== undefined &&
      patreonPledgeCents >= ART_CARD_FILES_CENTS,
    hasPatreonCardAccess:
      patreonPledgeCents !== undefined &&
      patreonPledgeCents >= ART_CARD_FILES_CENTS,
  }
}

const mapDispatchToProps = (
  dispatch: StoreDispatch,
  ownProps: RouteComponentProps<{id: string}>,
) => ({
  dispatch,
  setSelectedItemId: (id: string) =>
    dispatch(
      actions.setSelectedItem(id, listIdFromLocation(ownProps.location)),
    ),
  setItemFave: (item: types.GriffonItem, fave: boolean) =>
    dispatch(thunks.setItemFave(item, fave)),
  addItemToBundle: (itemId: string, bundleId: string) =>
    dispatch(thunks.addItemToBundle(itemId, bundleId)),
})

const EXIT_ANIMATION_DURATION_MS = 300

const ItemDetailModalDialog: React.FC<{className?: string}> = props => (
  <motion.div
    style={{
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }}
    initial="hidden"
    animate="show"
    exit="hidden"
    variants={{
      hidden: {
        opacity: 0,
        backdropFilter: 'blur(0px)',
        transition: {duration: EXIT_ANIMATION_DURATION_MS / 1000},
      },
      show: {opacity: 1, backdropFilter: 'blur(3px)'},
    }}
    className={props.className}
  >
    {props.children}
  </motion.div>
)

class ItemDetailModal extends React.Component<
  Props,
  {modalShowing: boolean; isExiting: boolean}
> {
  constructor(props: Props) {
    super(props)
    this.keyHandler = this.keyHandler.bind(this)
    this.state = {modalShowing: false, isExiting: false}
  }

  getListId(): types.ListIdentifier {
    return listIdFromLocation(this.props.location)
  }

  pathForItem(item: null): null
  pathForItem(item: types.GriffonItem): string
  pathForItem(item: types.GriffonItem | null): string | null
  pathForItem(item: types.GriffonItem | null): string | null {
    if (!item) return null
    const {selectedBundleId} = this.props
    const listId = this.getListId()
    const prefix =
      listId === 'stash' && selectedBundleId
        ? `/stashes/${selectedBundleId}`
        : ''
    return prefix + `/items/${item.id}`
  }

  navigateToItem(item: types.GriffonItem | null) {
    if (item) {
      this.props.history.replace(this.pathForItem(item))
    }
  }
  exit() {
    this.setState({isExiting: true})
    setTimeout(() => this.props.exit(), EXIT_ANIMATION_DURATION_MS)
  }

  keyHandler(event: KeyboardEvent) {
    switch (event.key) {
      case 'Esc':
      case 'Escape':
        event.stopImmediatePropagation()
        this.exit()
        break
      case 'Left':
      case 'ArrowLeft':
        this.navigateToItem(this.props.previousItem)
        break
      case 'Right':
      case 'ArrowRight':
        this.navigateToItem(this.props.nextItem)
        break
    }
  }
  componentDidMount() {
    document.addEventListener('keyup', this.keyHandler)
  }
  componentWillUnmount() {
    document.removeEventListener('keyup', this.keyHandler)
  }

  updateSelectedItemIdFromParams() {
    if (this.props.selectedItemId !== this.props.match.params.id) {
      this.props.setSelectedItemId(this.props.match.params.id)
    }
  }

  UNSAFE_componentWillMount() {
    this.updateSelectedItemIdFromParams()
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.updateSelectedItemIdFromParams()
    }
  }

  getGalleryItems() {
    return [
      this.props.previousItem,
      this.props.item,
      this.props.nextItem,
    ].filter((i: types.GriffonItem | null): i is types.GriffonItem =>
      Boolean(i),
    )
  }

  swipeEl: ReactSwipe | null = null
  render() {
    const {
      item,
      selectedItemId,
      detailMode,
      hasPatreonArtAccess,
      hasPatreonCardAccess,
    } = this.props
    const items = this.getGalleryItems()
    const itemIndex = items.indexOf(item)
    const isCardView = detailMode === 'card'
    const DetailGalleryItemComponent = isCardView
      ? ItemDetailCardGalleryItem
      : ItemDetailModalGalleryItem

    return (
      <AnimatePresence>
        {!item ||
        selectedItemId !== this.props.match.params.id ||
        this.state.isExiting ? null : (
          <Modal
            show
            animation={false}
            onShow={() => {
              this.setState({modalShowing: true})
            }}
            backdrop={false}
            onHide={() => this.props.exit()}
            dialogAs={ItemDetailModalDialog}
          >
            <div />
            <FloatingItemNavigationButton
              forCard={isCardView}
              item={this.props.previousItem}
              direction="previous"
              to={this.pathForItem(this.props.previousItem)}
            />
            <FloatingItemNavigationButton
              forCard={isCardView}
              item={this.props.nextItem}
              direction="next"
              to={this.pathForItem(this.props.nextItem)}
            />
            <ReactSwipe
              ref={o => (this.swipeEl = o)}
              style={{
                wrapper: {
                  zIndex: 950, // above washCardHeaderBack
                  position: 'relative',
                },
                container: {},
                child: {},
              }}
              swipeOptions={{
                continuous: false,
                transitionEnd: (i: number) => {
                  if (items[i] !== item) {
                    this.navigateToItem(items[i])
                  }
                },
                startSlide: itemIndex,
              }}

              // TODO: can we re-set up this when selectedItemId changes?
              // OR: try to send the entire listId in to this component
              //     and render all items as ItemDetailModalGalleryItem.
              //     The "further out" items could be wrapped in LazyLoad
              //     to prevent unnecessary renders, or just render them as
              //     "null" in the map here, until they come within +/- 1 or 2
              //     of the current selected item
            >
              {items.map(galleryItem => (
                <DetailGalleryItemComponent
                  key={galleryItem.id}
                  item={galleryItem}
                  user={this.props.user}
                  exit={() => this.exit()}
                  hasPatreonArtAccess={hasPatreonArtAccess}
                  hasPatreonCardAccess={hasPatreonCardAccess}
                />
              ))}
            </ReactSwipe>
            {isCardView && (
              <>
                <img
                  src="/images/wash-card-header-back.png"
                  alt=""
                  className={[
                    styles.washCardHeaderBack,
                    utilityStyles.lightOnly,
                  ].join(' ')}
                />
                <img
                  src="/images/wash-card-header-back-dark.png"
                  alt=""
                  className={[
                    styles.washCardHeaderBack,
                    utilityStyles.darkOnly,
                  ].join(' ')}
                />
              </>
            )}
          </Modal>
        )}
      </AnimatePresence>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ItemDetailModal),
)
