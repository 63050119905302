import React, {useState, useEffect} from 'react'

import Modal from 'react-bootstrap/Modal'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'

import {myFirebase} from '../../firebase/firebase'

import Layout from '../Layout'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCopy, faDiceD20} from '@fortawesome/free-solid-svg-icons'
import {getFunctions, httpsCallable} from 'firebase/functions'
import {Alert} from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'

export default function GenerateMagicLink() {
  const generateMagicLink = httpsCallable(
    getFunctions(myFirebase),
    'adminGenerateLoginLink',
  )
  const [email, setEmail] = useState('')
  const [magicLink, setMagicLink] = useState('')
  const [alert, setAlert] = useState<null | {
    text: string
    variant: 'success' | 'danger'
  }>(null)

  const [isSubmitting, setSubmitting] = useState(false)

  const submit = async () => {
    if (isSubmitting) return
    const trimmedEmail = email.trim()
    if (!trimmedEmail) return

    setSubmitting(true)

    try {
      const response = await generateMagicLink({
        email: trimmedEmail,
        url: window.location.origin,
      })
      setMagicLink(response.data as string)
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err: any = e
      setSubmitting(false)
      setAlert({text: err.message, variant: 'danger'})
      console.error(err)
      return
    }
    setSubmitting(false)
  }

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null)
      }, 5000)
    }
  }, [alert])

  return (
    <Layout>
      <Alert
        dismissible
        variant={alert?.variant}
        show={Boolean(alert)}
        transition
      >
        {alert?.text}
      </Alert>
      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>Generate Magic Login Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {magicLink ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <FormControl disabled value={magicLink} />
              <CopyToClipboard
                text={magicLink}
                onCopy={(_, success) => {
                  if (success) {
                    setAlert({
                      text: 'Copied to clipboard',
                      variant: 'success',
                    })
                  } else {
                    setAlert({
                      text: 'Failed to copy to clipboard',
                      variant: 'danger',
                    })
                  }
                }}
              >
                <Button variant="light">
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </CopyToClipboard>
            </div>
          ) : (
            <FormControl
              placeholder="User email"
              type="text"
              autoFocus
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {magicLink ? (
            <div style={{display: 'flex', gap: '1rem'}}>
              <Button
                onClick={() => {
                  setMagicLink('')
                  setEmail('')
                }}
              >
                Restart
              </Button>
            </div>
          ) : (
            <Button onClick={submit} disabled={isSubmitting || !email.trim()}>
              {isSubmitting ? (
                <>
                  <FontAwesomeIcon icon={faDiceD20} spin /> Loading&hellip;
                </>
              ) : (
                'Generate'
              )}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Dialog>
    </Layout>
  )
}
