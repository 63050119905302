import React, {FormEvent, ReactNode, useState} from 'react'
import Layout from '../Layout'

import {
  Alert,
  Button,
  Container,
  FormControl,
  FormLabel,
  Modal,
} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDiceD20} from '@fortawesome/free-solid-svg-icons'
import {getFunctions, httpsCallable} from 'firebase/functions'
import {myFirebase} from '../../firebase/firebase'

export default function UpdateUserEmail() {
  const [currentEmail, setCurrentEmail] = useState('')
  const [updatedEmail, setUpdatedEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [alert, setAlert] = useState<{text: ReactNode; variant: string} | null>(
    null,
  )

  const buttonDisabled =
    !currentEmail ||
    !updatedEmail ||
    isSubmitting ||
    currentEmail === updatedEmail

  const updateUserEmail = httpsCallable(
    getFunctions(myFirebase),
    'adminUpdateUserEmail',
  )

  const submit = async (e: FormEvent) => {
    e.preventDefault()
    if (isSubmitting) return
    setAlert(null)
    setIsSubmitting(true)
    try {
      await updateUserEmail({
        currentEmail: currentEmail.trim(),
        updatedEmail: updatedEmail.trim(),
      })
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err: any = e
      setIsSubmitting(false)
      setAlert({text: err.message, variant: 'danger'})
      console.error(err)
      return
    }
    setAlert({
      text: (
        <>
          Successfully updated email from <strong>{currentEmail.trim()}</strong>{' '}
          to <strong>{updatedEmail.trim()}</strong>
        </>
      ),
      variant: 'success',
    })
    setIsSubmitting(false)
  }

  return (
    <Layout>
      <Alert
        dismissible
        variant={alert?.variant}
        show={Boolean(alert)}
        transition
      >
        {alert?.text}
      </Alert>
      <form onSubmit={submit}>
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Update user email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container
              style={{gap: '1rem', display: 'flex', flexDirection: 'column'}}
            >
              <div>
                <FormLabel>Current email</FormLabel>
                <FormControl
                  placeholder="Current email"
                  type="text"
                  value={currentEmail}
                  onChange={e => setCurrentEmail(e.target.value)}
                />
              </div>
              <div>
                <FormLabel>New email</FormLabel>
                <FormControl
                  placeholder="New email"
                  type="text"
                  value={updatedEmail}
                  onChange={e => setUpdatedEmail(e.target.value)}
                />
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={buttonDisabled}>
              {isSubmitting ? (
                <>
                  <FontAwesomeIcon icon={faDiceD20} spin /> Loading&hellip;
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </form>
    </Layout>
  )
}
