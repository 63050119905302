import React from 'react'
import {Link} from 'react-router-dom'
import classnames from 'classnames'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faEdit,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons'
import {faCircle} from '@fortawesome/free-regular-svg-icons'

import {fullItemType, shortFormatSubrarity} from '../utils'
import {DateColumnMode, GriffonItem, SortColumn} from '../store/types'

import ItemMetaThumbnail from './ItemMetaThumbnail'
import {thumbnailOverlay, attunementOverlay} from './itemListOverlays'
import ItemDateSnippet from './ItemDateSnippet'

import styles from './ItemList.module.scss'
import {motion} from 'framer-motion'

type SelectionStyle = 'select' | 'remove'

interface Props {
  item: GriffonItem
  selected: boolean
  showEditColumn: boolean
  showSelectColumn: boolean
  selectionStyle?: SelectionStyle
  visibleSecondaryColumn: Exclude<SortColumn, 'name'>
  freeColumnVisible: boolean
  dateColumnMode: DateColumnMode
  onClick: (item: GriffonItem) => void
  index: number
}

const DELAY_PER_ROW = 0.07
const STAGGERED_ITEMS = 5

const ItemListRow: React.FC<Props> = props => {
  const {
    item,
    selected,
    showEditColumn,
    showSelectColumn,
    selectionStyle,
    visibleSecondaryColumn,
    freeColumnVisible,
    dateColumnMode,
    onClick,
    index,
  } = props

  return (
    <motion.tr
      style={{cursor: 'pointer'}}
      onClick={() => onClick(item)}
      variants={{
        open: {
          y: 0,
          opacity: 1,
          transition: {
            delay:
              index < STAGGERED_ITEMS
                ? DELAY_PER_ROW * index
                : DELAY_PER_ROW * STAGGERED_ITEMS,
            transition: {type: 'spring', stiffness: 300, damping: 24},
          },
        },
        closed: {
          y: 20,
          opacity: 0,
        },
      }}
    >
      <td className="edge" />
      {showEditColumn && (
        <td className="d-none d-md-table-cell">
          <Link
            to={`/items/${item.id}/edit`}
            // prevent detail view from opening
            onClick={e => e.stopPropagation()}
          >
            <FontAwesomeIcon icon={faEdit} fixedWidth />
          </Link>
        </td>
      )}
      {showSelectColumn && (
        <td>
          <FontAwesomeIcon
            icon={
              selected
                ? selectionStyle === 'remove'
                  ? faMinusCircle
                  : faCheckCircle
                : faCircle
            }
            className="text-primary"
          />
        </td>
      )}
      <td
        style={{padding: '2px', height: '1px'}}
        className="d-none d-md-table-cell"
      >
        <ItemMetaThumbnail item={item} width="33px" />
      </td>
      <td style={{padding: '2px', position: 'relative'}}>
        {item.imageIds?.[0] && thumbnailOverlay(item.imageIds?.[0])}
      </td>
      <td>{item.name}</td>
      <td
        className={classnames(styles.dataColumn, {
          [styles.columnVisible]: visibleSecondaryColumn === 'type',
        })}
      >
        {fullItemType(item)}
      </td>
      <td
        className={classnames(styles.dataColumn, 'text-center', {
          [styles.columnVisible]: visibleSecondaryColumn === 'rarity',
        })}
      >
        {item.rarity}
      </td>
      <td
        className={classnames(styles.dataColumn, 'text-center', {
          [styles.columnVisible]: visibleSecondaryColumn === 'attunement',
        })}
        style={{position: 'relative'}}
      >
        {attunementOverlay(item)}
      </td>
      <td
        className={classnames(styles.dataColumn, 'text-center', {
          [styles.columnVisible]: visibleSecondaryColumn === 'significance',
        })}
      >
        {shortFormatSubrarity(item)}
      </td>
      <td
        className={classnames(styles.dataColumn, 'text-center', {
          [styles.columnVisible]: visibleSecondaryColumn === 'date',
        })}
        style={{whiteSpace: 'nowrap'}}
      >
        <ItemDateSnippet item={item} dateColumnMode={dateColumnMode} />
      </td>
      {freeColumnVisible && (
        <td
          className={classnames(styles.dataColumn, 'text-center', {
            [styles.columnVisible]: visibleSecondaryColumn === 'free',
          })}
        >
          {item.free && (
            <FontAwesomeIcon icon={faCheckCircle} className={styles.rowIcon} />
          )}
        </td>
      )}
      <td className="edge" />
    </motion.tr>
  )
}

export default ItemListRow
